import TypesenseInstantSearchAdapterExport from "typesense-instantsearch-adapter";

const TypesenseInstantSearchAdapter =
  // @ts-expect-error Property 'default' does not exist
  TypesenseInstantSearchAdapterExport.default ?? TypesenseInstantSearchAdapterExport;

// A helper function that abstracts and validates config
function getConfig() {
  if (typeof window !== "undefined" && window.ENV) {
    console.log("Creating TS config from window.ENV");
    return {
      searchKey: window.ENV.TYPESENSE_SEARCH_KEY || "",
      clusterUrl: window.ENV.TYPESENSE_CLUSTER_URL || "",
    };
  }

  if (typeof process !== "undefined" && process.env) {
    console.log("Creating TS config from process.env");
    return {
      searchKey: process.env.TYPESENSE_SEARCH_KEY || "",
      clusterUrl: process.env.TYPESENSE_CLUSTER_URL || "",
    };
  }

  console.error("No configuration available for Typesense");
  // throw new Error("No configuration available for Typesense");
  return {
    searchKey: "",
    clusterUrl: "",
  };
}

// Attempt to create the adapter or fall back safely
let typesenseInstantsearchAdapter: any;
const config = getConfig();
try {
  typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
      apiKey: config.searchKey,
      nodes: [
        {
          host: config.clusterUrl,
          port: 443,
          protocol: "https",
        },
      ],
      connectionTimeoutSeconds: 10,
    },
    collectionSpecificSearchParameters: {
      posts: {
        query_by: "title,stringifiedContent",
        query_by_weights: "127,90",
        facet_by: "platforms,tags,agency,postType,status",
        sort_by: "publishedAt:desc",
        limit: 24,
        exclude_fields: "embedding",
      },
      sam_entities: {
        query_by: "display_name,uei",
        facet_by: "socioeconomic_statuses,is_published,state,primary_naics,zip_code",
        filter_by: "is_published:true",
        limit: 24,
      },
      agencies: {
        query_by: "agency_name,code",
        facet_by: "agency_name,code,abbreviation",
        limit: 24,
      },
    },
  });
} catch (error) {
  console.error("Failed to initialize Typesense:", error);
  // Fallback: provide a dummy adapter that returns empty search results
  typesenseInstantsearchAdapter = {
    searchClient: {
      search: async () => ({ results: [] }),
    },
  };
}

const homeInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: config.searchKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: config.clusterUrl,
        port: 443,
        protocol: "https",
      },
    ],
    connectionTimeoutSeconds: 10,
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  // So you can pass any parameters supported by the search endpoint below.
  // queryBy is required.
  additionalSearchParameters: {
    query_by: "title",
    facet_by: "platforms,tags,agency,postType,status",
    sort_by: "publishedAt:desc",
    limit: 15,
    exclude_fields: "embedding",
  },
});

const pipelineOpportunityInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: config.searchKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: config.clusterUrl,
        port: 443,
        protocol: "https",
      },
    ],
    connectionTimeoutSeconds: 10,
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  // So you can pass any parameters supported by the search endpoint below.
  // queryBy is required.
  additionalSearchParameters: {
    query_by: "title",
    facet_by: "platforms,tags,agency,postType,status",
    sort_by: "publishedAt:desc",
    limit: 15,
    exclude_fields: "embedding",
  },
});

const recommendationsInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: config.searchKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: config.clusterUrl,
        port: 443,
        protocol: "https",
      },
    ],
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  // So you can pass any parameters supported by the search endpoint below.
  // queryBy is required.
  additionalSearchParameters: {
    query_by: "embedding",
    sort_by: "publishedAt:desc",
    limit: 8,
    exclude_fields: "embedding",
  },
});

const typesenseSuggestionsInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: config.searchKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: config.clusterUrl,
        port: 443,
        protocol: "https",
      },
    ],
    connectionTimeoutSeconds: 10,
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  additionalSearchParameters: {
    query_by: "title",
    sort_by: "publishedAt:desc",
    facet_by: "status",
    limit: 5,
    facet_query: "status:published",
    per_page: 5,
    exclude_fields: "embedding",
  },
});

const typesenseRelatedInsightsInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: config.searchKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: config.clusterUrl,
        port: 443,
        protocol: "https",
      },
    ],
    connectionTimeoutSeconds: 10,
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  // So you can pass any parameters supported by the search endpoint below.
  // queryBy is required.
  additionalSearchParameters: {
    query_by: "title,stringifiedContent",
    sort_by: "publishedAt:desc",
    facet_by: "status",
    limit: 9,
    facet_query: "status:published",
    per_page: 9,
    exclude_fields: "embedding",
  },
});

const typesenseCompaniesInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: config.searchKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: config.clusterUrl,
        port: 443,
        protocol: "https",
      },
    ],
    connectionTimeoutSeconds: 10,
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  // So you can pass any parameters supported by the search endpoint below.
  // queryBy is required.
  additionalSearchParameters: {
    query_by: "display_name,uei",
    facet_by: "socioeconomic_statuses,is_published,state,primary_naics,zip_code",
    filter_by: "is_published:true",
    limit: 24,
  },
});

const typesenseExploreCompaniesInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: config.searchKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: config.clusterUrl,
        port: 443,
        protocol: "https",
      },
    ],
    connectionTimeoutSeconds: 10,
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  // So you can pass any parameters supported by the search endpoint below.
  // queryBy is required.
  additionalSearchParameters: {
    query_by: "display_name,uei",
    facet_by: "socioeconomic_statuses,is_published",
    filter_by: "is_published:true",
    limit: 8,
  },
});

const typesenseExploreAgenciesInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: config.searchKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: config.clusterUrl,
        port: 443,
        protocol: "https",
      },
    ],
    connectionTimeoutSeconds: 10,
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  // So you can pass any parameters supported by the search endpoint below.
  // queryBy is required.
  additionalSearchParameters: {
    query_by: "agency_name,code,abbreviation",
    limit: 24,
    per_page: 24,
    q: "*", // Search for all documents if empty query
    filter_by: "agency_name:= 'DEPARTMENT OF THE AIR FORCE' || agency_name:= 'DEPARTMENT OF THE ARMY' || agency_name:= 'DEPARTMENT OF DEFENSE' || agency_name:= 'DEPARTMENT OF THE NAVY' || agency_name:= 'DEPARTMENT OF AGRICULTURE' || agency_name:= 'DEPARTMENT OF COMMERCE' || agency_name:= 'DEPARTMENT OF JUSTICE' || agency_name:= 'DEPARTMENT OF LABOR' || agency_name:= 'DEPARTMENT OF ENERGY' || agency_name:= 'DEPARTMENT OF EDUCATION' || agency_name:= 'DEPARTMENT OF HEALTH AND HUMAN SERVICES' || agency_name:= 'DEPARTMENT OF HOMELAND SECURITY' || agency_name:= 'DEPARTMENT OF HOUSING AND URBAN DEVELOPMENT' || agency_name:= 'DEPARTMENT OF THE INTERIOR' || agency_name:= 'DEPARTMENT OF STATE' || agency_name:= 'DEPARTMENT OF TRANSPORTATION' || agency_name:= 'DEPARTMENT OF THE TREASURY' || agency_name:= 'DEPARTMENT OF VETERANS AFFAIRS'"
  },
});

const typesenseExploreOpportunityInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: config.searchKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: config.clusterUrl,
        port: 443,
        protocol: "https",
      },
    ],
    connectionTimeoutSeconds: 10,
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  // So you can pass any parameters supported by the search endpoint below.
  // queryBy is required.
  additionalSearchParameters: {
    query_by: "embedding",
    prefix: false,
    limit: 10,
    per_page: 9,
  },
});

const typesenseInstantsearchAdapterGlobalSearch = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: config.searchKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: config.clusterUrl,
        port: 443,
        protocol: "https",
      },
    ],
    connectionTimeoutSeconds: 10,
  },
  collectionSpecificSearchParameters: {
    posts: {
      query_by: "title,stringifiedContent",
      query_by_weights: "127,90",
      facet_by: "platforms,tags,agency,postType,status",
      sort_by: "publishedAt:desc",
      limit: 10,
      exclude_fields: "embedding",
    },
    sam_entities: {
      query_by: "display_name,uei",
      facet_by: "socioeconomic_statuses,is_published,state,primary_naics,zip_code",
      filter_by: "is_published:true",
      limit: 10,
    },
    agencies: {
      query_by: "agency_name,code",
      facet_by: "agency_name,code,abbreviation",
      limit: 10,
    },
  },
});

const typesenseExploreNaicsAndPscInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: config.searchKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: config.clusterUrl,
        port: 443,
        protocol: "https",
      },
    ],
    connectionTimeoutSeconds: 10,
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  // So you can pass any parameters supported by the search endpoint below.
  // queryBy is required.
  additionalSearchParameters: {
    query_by: "embedding",
    sort_by: "publishedAt:desc",
    prefix: false,
    limit: 9,
    per_page: 9,
  },
});

export const searchClient = typesenseInstantsearchAdapter.searchClient;
export const suggestionsClient = typesenseSuggestionsInstantsearchAdapter.searchClient;
export const relatedInsightsClient = typesenseRelatedInsightsInstantsearchAdapter.searchClient;
export const recommendationsClient = recommendationsInstantsearchAdapter.searchClient;
export const homeClient = homeInstantsearchAdapter.searchClient;
export const pipelineOpportunityClient = pipelineOpportunityInstantsearchAdapter.searchClient;
export const companiesClient = typesenseCompaniesInstantsearchAdapter.searchClient;
export const exploreCompaniesClient = typesenseExploreCompaniesInstantsearchAdapter.searchClient;
export const opportunityClient = typesenseExploreOpportunityInstantsearchAdapter.searchClient;
export const globalSearchClient = typesenseInstantsearchAdapterGlobalSearch.searchClient;
export const naicsSearchClient = typesenseExploreNaicsAndPscInstantsearchAdapter.searchClient;
export const agenciesClient = typesenseExploreAgenciesInstantsearchAdapter.searchClient;
